// import { URLSearchParams } from 'node/url';
import qs from 'query-string'
import { select, all, takeLatest } from 'redux-saga/effects'

import { history } from '../../store'
import { constants as sessionConstants } from './creator'

function* handleSessionInit({ payload = {} }) {
  const { isFirstRendering = false, location } = payload
  if (isFirstRendering) {
    console.log(location)
  }
  return yield payload
}

function* handleUserLogout({ payload = {} }) {
  localStorage.clear()
  document.cookie = `authToken=; expires=${new Date()};  path=/`

  const { search } = yield select(({ router }) => router.location)
  const { a, sa } = qs.parse(search)

  return yield history.push(
    payload.redirect || {
      pathname: '/',
      search: qs.stringify({ a, sa }),
    },
  )
}

export default function* sagas() {
  yield all([
    takeLatest('@@router/LOCATION_CHANGE', handleSessionInit),

    takeLatest(sessionConstants.USER_LOGOUT, handleUserLogout),
  ])
}
