import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import {
  CContainer,
  CHeader,
  CHeaderBrand,
  CHeaderDivider,
  CHeaderNav,
  CHeaderToggler,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilMenu } from '@coreui/icons'
import { toggleSideBarVisibility } from '../redux/modules/browser/creator'
import { sidebarVisibility } from '../redux/modules/browser/selector'

import { AppBreadcrumb } from './index'
import { AppHeaderDropdown } from './header/index'
import LogoDark from '../assets/brand/logo-dark.svg'

const AppHeader = ({ toggleSideBarVisibility, sidebarVisibility }) => {
  return (
    <CHeader position="sticky" className="mb-4">
      <CContainer fluid>
        <CHeaderToggler className="ps-1" onClick={toggleSideBarVisibility}>
          <CIcon icon={cilMenu} size="lg" />
        </CHeaderToggler>
        <CHeaderBrand className="mx-auto" to="/">
          {sidebarVisibility ? null : (
            <img src={LogoDark} alt="rapido-logo" style={{ height: '48px' }} />
          )}
        </CHeaderBrand>
        <CHeaderNav className="ms-3">
          <AppHeaderDropdown />
        </CHeaderNav>
      </CContainer>
      <CHeaderDivider />
      <CContainer fluid>
        <AppBreadcrumb />
      </CContainer>
    </CHeader>
  )
}
AppHeader.propTypes = {
  toggleSideBarVisibility: PropTypes.func,
  sidebarVisibility: PropTypes.bool,
}
const mapStateToProps = (state) => ({
  sidebarVisibility: sidebarVisibility(state),
})
const mapActionsToProps = {
  toggleSideBarVisibility,
}
export default connect(mapStateToProps, mapActionsToProps)(AppHeader)
