import React from 'react'
import HealthCardsCreate from './views/HealthCards/create'
import EditHealthCard from './views/HealthCards/edit'

const ReportsService = React.lazy(() => import('./views/Reports'))
const HealthCardsModule = React.lazy(() => import('./views/HealthCards'))

const routes = [
  { path: '/', exact: true, name: 'Home' },
  // { path: '/', exact: true, name: 'Dashboard', component: Dashboard },
  { path: '/reports-service', name: 'Reports', component: ReportsService },
  { path: '/health-cards/create-new', name: 'Create Health Card', component: HealthCardsCreate },
  {
    path: '/health-cards/edit/:card_id',
    pathRegexp: '/health-cards/edit/*',
    name: 'Edit Health Card',
    component: EditHealthCard,
  },
  { path: '/health-cards', name: 'Health Cards', component: HealthCardsModule },
]

export default routes
