import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Route, Switch } from 'react-router-dom'
import { useKeycloak } from '@react-keycloak/web'
import './scss/style.scss'
import Loader from './components/Loader'
import { setUserProfileData } from './redux/modules/session/creator'

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// eslint-disable-next-line react/prop-types
const App = ({ setUserProfileData }) => {
  const { keycloak, initialized } = useKeycloak()

  console.log({ token: keycloak.token })
  useEffect(() => {
    const func = async () => {
      const isAuthenticated = keycloak.authenticated
      console.log({ initialized, isAuthenticated })
      if (initialized && !isAuthenticated) {
        keycloak.login()
      }
    }
    func()
  }, [initialized, keycloak.authenticated])
  useEffect(() => {
    console.log({
      token: keycloak.token,
      authenticated: keycloak.authenticated,
      initialized,
      user: keycloak.user,
    })
    if (keycloak.token) {
      document.cookie = `authToken=${keycloak.token}; max-age=${60 * 60 * 1000}; path=/`
    } else {
      console.log('Resetting the token')
      document.cookie = `authToken=NO-TOKEN; expires=${new Date()}; path=/`
    }
    keycloak
      .loadUserProfile()
      .then((profile) => {
        setUserProfileData(profile)
      })
      .catch(() => {
        console.error('Failed to load user profile')
      })
  }, [keycloak.token, keycloak.authenticated, initialized])

  return (
    <>
      <React.Suspense fallback={Loader}>
        <Switch>
          {/*<Route exact path="/login" name="Login Page" render={(props) => <Login {...props} />} />*/}
          {/*<Route exact path="/404" name="Page 404" render={(props) => <Page404 {...props} />} />*/}
          {/*<Route exact path="/500" name="Page 500" render={(props) => <Page500 {...props} />} />*/}
          <Route path="/" name="Home" render={(props) => <DefaultLayout {...props} />} />
        </Switch>
      </React.Suspense>
    </>
  )
}

const mapStateToProps = (state) => ({})
const mapDispatchToProps = {
  setUserProfileData,
}

export default connect(mapStateToProps, mapDispatchToProps)(App)
