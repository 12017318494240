import { createAsyncActions } from '../utils'

//#region Action constant
export const reportsServicesConstants = {
  GET_USER_REPORT_REQUESTS: createAsyncActions('@@reports-service/GET_USER_REPORT_REQUESTS'),
  SET_USER_REPORT_REQUESTS: '@@reports-service/SET_USER_REPORT_REQUESTS',
  LOAD_MORE: createAsyncActions('@@reports-service/LOAD_MORE'),
  APPLY_FILTER: createAsyncActions('@@reports-service/APPLY_FILTER'),
  SET_FILTER: '@@reports-service/SET_FILTER',
  APPLY_SORT: createAsyncActions('@@reports-service/APPLY_SORT'),
  SET_SORT: '@@reports-service/SET_SORT',

  SET_LOADING_STATE: '@@reports-service/SET_LOADING_STATE',
}
//#endregion Action constant

//#region Action creators
export const fetchReportRequests = (payload = {}, meta = {}) => ({
  type: reportsServicesConstants.GET_USER_REPORT_REQUESTS.TRIGGER,
  payload,
  meta,
})

export const setReportRequests = (payload = {}, meta = {}) => ({
  type: reportsServicesConstants.SET_USER_REPORT_REQUESTS,
  payload,
  meta,
})

export const loadMoreReportRequests = (payload = {}, meta = {}) => ({
  type: reportsServicesConstants.LOAD_MORE.TRIGGER,
  payload,
  meta,
})

export const applyFilter = (payload, meta = {}) => ({
  type: reportsServicesConstants.APPLY_FILTER.TRIGGER,
  payload,
  meta,
})

export const setFilter = (payload, meta = {}) => ({
  type: reportsServicesConstants.SET_FILTER,
  payload,
  meta,
})
export const applySort = (payload, meta = {}) => ({
  type: reportsServicesConstants.APPLY_SORT.TRIGGER,
  payload,
  meta,
})

export const setSort = (payload, meta = {}) => ({
  type: reportsServicesConstants.SET_SORT,
  payload,
  meta,
})

export const setLoading = (payload) => ({
  type: reportsServicesConstants.SET_LOADING_STATE,
  payload,
})
//#endregion Action creators
