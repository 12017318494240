import { connectRouter } from 'connected-react-router'
import { combineReducers } from 'redux'

const requireModule = require.context('.', true, /\.js$/)

const autoImportedReducers = requireModule.keys().reduce((modules, folderName) => {
  if (folderName === './reducers.js' || !folderName.includes('/reducer.js')) {
    return modules
  }

  const importedModule = requireModule(folderName)
  const [moduleName] = folderName.replace(/(\.\/|\.js)/g, '').split('/')

  return {
    ...modules,
    [moduleName]: importedModule.default || importedModule,
  }
}, {})

const createRootReducers = (history) => (state, action) => {
  let newState = { ...state }
  return combineReducers({
    router: connectRouter(history),
    ...autoImportedReducers,
  })(newState, action)
}

export default createRootReducers
