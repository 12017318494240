import { select, all, put, takeLatest } from 'redux-saga/effects'

import { fetcherAction } from '../fetcher/creator'
import { getAuthToken } from '../fetcher/saga'
import { reportsServicesConstants, setFilter, setLoading, setSort } from './creator'
import { reportsTableFilter, reportsTableSort, userReportRequests } from './selector'

function* handleLocationChange({ payload = {} }) {
  const { isFirstRendering = false, location } = payload
  console.log(location)
  const activeModule = location.pathname.split('/')[1] || ''
  console.log({ activeModule })
  if (activeModule === 'reports-service') {
    // return yield put(fetchReportRequests())
  }

  return yield payload
}

function* fetchUserRequests({ payload = {} }) {
  yield put(setLoading(true))
  return yield put(
    fetcherAction({
      fetcherParams: {
        method: 'GET',
        url: '/reports',
        params: { ...payload },
      },
      tokenize: true,
      label: reportsServicesConstants.GET_USER_REPORT_REQUESTS,
    }),
  )
}
function* fetchUserRequestsOnFailure({ error }) {
  yield put(setLoading(false))
}

function* loadMoreUserRequests({ payload = {} }) {
  yield put(setLoading(true))
  const requests = yield select(userReportRequests)
  const sort = yield select(reportsTableSort)
  const filters = yield select(reportsTableFilter)
  return yield put(
    fetcherAction({
      fetcherParams: {
        method: 'GET',
        url: `/reports`,
        params: {
          ...filters,
          ...(sort.state && {
            orderKey: sort.column,
            orderDirection: `${sort.state}`.toUpperCase(),
          }),
          offset: requests.length,
        },
      },
      tokenize: true,
      label: reportsServicesConstants.LOAD_MORE,
    }),
  )
}
function* loadMoreUserRequestsOnFailure({ payload = {} }) {
  yield put(setLoading(false))
}

function* applyFilter({ payload }) {
  yield put(setLoading(true))
  yield put(setFilter(payload))
  const sort = yield select(reportsTableSort)

  return yield put(
    fetcherAction({
      fetcherParams: {
        method: 'GET',
        url: '/reports',
        params: { ...payload, ...sort },
      },
      label: reportsServicesConstants.GET_USER_REPORT_REQUESTS,
      tokenize: true,
    }),
  )
}
function* applyFilterOnFailure({ payload }) {
  yield put(setLoading(false))
}

function* applySort({ payload }) {
  yield put(setLoading(true))
  yield put(setSort(payload.state ? payload : {}))
  const filters = yield select(reportsTableFilter)
  return yield put(
    fetcherAction({
      fetcherParams: {
        method: 'GET',
        url: '/reports',
        params: {
          ...filters,
          ...(payload.state && {
            orderKey: payload.column,
            orderDirection: `${payload.state}`.toUpperCase(),
          }),
        },
      },
      tokenize: true,
      label: reportsServicesConstants.GET_USER_REPORT_REQUESTS,
    }),
  )
}
function* applySortOnFailure({ payload }) {
  yield put(setLoading(true))
  yield put(setSort(payload.state ? payload : {}))
  const filters = yield select(reportsTableFilter)
  return yield put(
    fetcherAction({
      fetcherParams: {
        method: 'GET',
        url: '/reports',
        params: {
          ...filters,
          ...(payload.state && {
            orderKey: payload.column,
            orderDirection: `${payload.state}`.toUpperCase(),
          }),
        },
      },
      tokenize: true,
      label: reportsServicesConstants.GET_USER_REPORT_REQUESTS,
    }),
  )
}

export default function* sagas() {
  yield all([
    takeLatest('@@router/LOCATION_CHANGE', handleLocationChange),
    takeLatest(reportsServicesConstants.GET_USER_REPORT_REQUESTS.TRIGGER, fetchUserRequests),
    takeLatest(
      reportsServicesConstants.GET_USER_REPORT_REQUESTS.FAILURE,
      fetchUserRequestsOnFailure,
    ),
    takeLatest(reportsServicesConstants.LOAD_MORE.TRIGGER, loadMoreUserRequests),
    takeLatest(reportsServicesConstants.LOAD_MORE.FAILURE, loadMoreUserRequestsOnFailure),

    takeLatest(reportsServicesConstants.APPLY_FILTER.TRIGGER, applyFilter),
    takeLatest(reportsServicesConstants.APPLY_FILTER.FAILURE, applyFilterOnFailure),

    takeLatest(reportsServicesConstants.APPLY_SORT.TRIGGER, applySort),
    takeLatest(reportsServicesConstants.APPLY_SORT.FAILURE, applySortOnFailure),
  ])
}
