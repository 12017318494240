import axios from 'axios'
import styled from 'styled-components'
import { environment } from '../../environments'
import { useKeycloak } from '@react-keycloak/web'
import TrashIcon from '../../assets/icons/trash.svg'
import { useHistory, useParams, Prompt } from 'react-router-dom'
import React, { useCallback, useEffect, useMemo, useReducer, useState } from 'react'
import { CCol, CFormInput, CFormLabel, CFormSelect, CFormTextarea } from '@coreui/react'

const Container = styled.div`
  padding: 50px 30px;
  background-color: white;

  width: 100%;
  min-height: 100vh;
  height: fit-content;
`
const AddNewButton = styled.button`
  width: 100%;
  height: 40px;
  border: dashed 2px #d8dbe0;
  border-radius: 0.25rem;
  background-color: transparent;
`
const FlexBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
`
const SaveButton = styled.button`
  width: fit-content;
  height: 40px;

  cursor: pointer;
  border: none;
  color: #121213;
  background-color: ${(p) => (p.color ? p.color : `#FFCA20EE`)};
  font-size: 14px;
  font-weight: 600;

  border-radius: 6px;
  box-shadow: rgba(27, 31, 35, 0.1) 0 1px 0;
  box-sizing: border-box;
  line-height: 20px;
  padding: 6px 16px;
  position: relative;
  text-align: center;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: middle;
  white-space: nowrap;

  :hover {
    background-color: #ffca20;
  }
  :focus:active {
    background-color: #ffca20;
    box-shadow: #ffca20a0 0 0 0 6px;
    outline: none;
  }
  :focus:not(:focus-visible):not(.focus-visible) {
    box-shadow: none;
    outline: none;
  }

  :disabled {
    opacity: 0.2;
    cursor: not-allowed;
  }
  margin: 0 30px;
`
const ResetFormButton = styled(SaveButton)`
  background-color: transparent;
  :hover {
    background-color: transparent;
    box-shadow: #ffca20a0 0 0 0 6px;
  }
  :focus:active {
    box-shadow: #ffca20a0 0 0 0 6px;
    outline: none;
  }
`

const BucketLocationPrefix = environment.BUCKET_LOCATION_PREFIX

const emptyMetricValue = {
  validationTag: 'raw_data_validation',
  metric: '',
  values: '',
  columnNames: '',
}
const formInitialState = {
  name: '',
  threshold: 1.5,
  namespace: '',
  metrics: [],
  newMetrics: [],
}

const EditHealthCard = (props) => {
  const history = useHistory()
  const params = useParams()
  const [shouldBlockNavigation, setShouldBlockNavigation] = useState(false)
  const [form, setForm] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    formInitialState,
  )
  const { keycloak } = useKeycloak()
  const cardId = useMemo(() => params.card_id, [params.card_id])

  useEffect(() => {
    if (shouldBlockNavigation) {
      window.onbeforeunload = () => true
    } else {
      window.onbeforeunload = undefined
    }
  })
  useEffect(() => {
    const { isTokenExpired } = keycloak
    console.log({ isTokenExpired: isTokenExpired() })
    if (isTokenExpired()) {
      keycloak
        .updateToken(30)
        .then(() => {
          console.log('successfully get a new token', keycloak.token)
        })
        .catch(() => {
          console.log('Failed to refresh token')
          keycloak.logout()
        })
    }
  }, [])
  useEffect(() => {
    const funct = async () => {
      const healthCard = await axios.get(`${environment.API_BASE_URL}/health-cards/${cardId}`, {
        headers: {
          Authorization: `Bearer ${keycloak.token}`,
          'Content-Type': 'application/json',
        },
      })
      setForm({ ...healthCard.data, ...JSON.parse(healthCard.data.config) })
      setShouldBlockNavigation(false)
    }
    funct()
  }, [params.card_id])
  useEffect(() => {
    const iframes = document.getElementsByTagName('iframe')
    for (const _ of iframes) {
      if (_.getAttribute('src')) {
        console.log(_.getAttribute('src'))
      } else {
        // To remove the overlay that prevents user to interact with app
        _.parentElement.removeChild(_)
      }
    }
  }, [props])

  const deleteMetricEntry = useCallback(
    (index) => () => {
      setForm({ metrics: form.metrics.filter((_, i) => i !== index) })
      setShouldBlockNavigation(true)
    },
    [form.metrics],
  )
  const updateMetricEntry = useCallback(
    (index, key) =>
      ({ target: { value } }) => {
        setForm({
          metrics: form.metrics.map((_, i) => ({ ..._, ...(i === index && { [key]: value }) })),
        })
        setShouldBlockNavigation(true)
      },
    [form.metrics],
  )
  const addNewMetric = useCallback(() => {
    setForm({ newMetrics: [...form.newMetrics, emptyMetricValue] })
    setShouldBlockNavigation(true)
  }, [form.newMetrics])
  const deleteNewMetricEntry = useCallback(
    (index) => () => {
      setForm({ newMetrics: form.newMetrics.filter((_, i) => i !== index) })
      setShouldBlockNavigation(true)
    },
    [form.newMetrics],
  )
  const updateNewMetricEntry = useCallback(
    (index, key) =>
      ({ target: { value } }) => {
        setForm({
          newMetrics: form.newMetrics.map((_, i) => ({
            ..._,
            ...(i === index && { [key]: value }),
          })),
        })
        setShouldBlockNavigation(true)
      },
    [form.newMetrics],
  )

  const updateConfig = useCallback(
    async (e) => {
      e.preventDefault()
      try {
        await axios.patch(
          `${environment.API_BASE_URL}/health-cards/${form.id}/edit`,
          {
            threshold: Number(form.threshold),
            dashboard_link: form.dashboard_link,
            emailsToAlert: form.emailsToAlert,
            metrics: [
              ...form.metrics.map((metric) => ({
                ...metric,
                gcs: `${BucketLocationPrefix}${form.name}/${metric.metric}/`,
                table: `${form.namespace}.${form.name}_${metric.metric}`,
                values: metric.values.replaceAll('\n', ' '),
              })),
              ...form.newMetrics.map((metric) => ({
                ...metric,
                gcs: `${BucketLocationPrefix}${form.name}/${metric.metric}/`,
                table: `${form.namespace}.${form.name}_${metric.metric}`,
                values: metric.values.replaceAll('\n', ' '),
              })),
            ],
          },
          {
            headers: {
              Authorization: `Bearer ${keycloak.token}`,
              'Content-Type': 'application/json',
            },
          },
        )
        setShouldBlockNavigation(false)
        history.push('/health-cards')
      } catch (err) {
        alert(err)
      }
    },
    [keycloak.token, form],
  )
  const cancel = () => {
    history.replace('/health-cards')
  }

  return (
    <Container>
      <h1>Edit Health Card</h1>
      <br />
      <form>
        <CFormLabel>Health Card Name</CFormLabel>
        <CFormInput type="text" style={{ width: '400px' }} value={form.name} required readOnly />
        <br />
        <CFormLabel>Threshold (Maximum accepted deviation between Raw and Dataset)</CFormLabel>
        <CFormInput
          type="number"
          style={{ width: '100px' }}
          min={0}
          max={100}
          step={0.1}
          value={form.threshold}
          onChange={(e) => setForm({ threshold: e.target.value })}
        />
        <br />
        <CFormLabel>Namespace</CFormLabel>
        <CFormInput type="text" style={{ width: '400px' }} value={form.namespace} readOnly />
        <br />
        <CFormLabel>Final health alert GCS location</CFormLabel>
        <CFormInput type="text" readOnly value={form.final_health_gcs} />
        <br />
        <CFormLabel>Final health alert table name</CFormLabel>
        <CFormInput type="text" readOnly value={form.final_health_table} />
        <br />
        <CFormLabel>Raw vs dataset comparison GCS location</CFormLabel>
        <CFormInput type="text" readOnly value={form.raw_vs_dataset_gcs} />
        <br />
        <CFormLabel>Raw vs dataset comparison table name</CFormLabel>
        <CFormInput type="text" readOnly value={form.raw_vs_dataset_table} />
        <br />
        <CFormLabel>
          Alerts Manager (Enter Email ids of users who wants to be alerted on health card updates)
        </CFormLabel>
        <CFormInput
          type="text"
          value={form.emailsToAlert}
          onChange={(e) => setForm({ emailsToAlert: e.target.value.replaceAll(' ', '') })}
        />
        <br />
        <CFormLabel>Dashboard link</CFormLabel>
        <CFormInput
          type="text"
          required
          value={`${form.dashboard_link}`}
          onChange={(e) => setForm({ dashboard_link: e.target.value })}
        />
        <br />
        {form.metrics.map((metric, i) => (
          <>
            <CCol
              sm={10}
              style={{
                border: 'solid 1px #d8dbe0',
                width: '100%',
                padding: '0 30px 30px',
                borderRadius: '10px',
              }}
            >
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginTop: '10px',
                }}
              >
                <CFormLabel>Metric #{i + 1}</CFormLabel>
                <div>
                  <img
                    src={TrashIcon}
                    alt="delete"
                    style={{ width: '18px', cursor: 'pointer' }}
                    onClick={deleteMetricEntry(i)}
                  />
                </div>
              </div>
              <CFormLabel>Validation tag</CFormLabel>
              <CFormSelect
                style={{ width: '400px' }}
                aria-label=""
                disabled={true}
                onChange={updateMetricEntry(i, 'validationTag')}
                options={[
                  {
                    label: 'Raw data validation',
                    value: 'raw_data_validation',
                  },
                  {
                    label: 'Raw vs Dataset validation',
                    value: 'raw_vs_dataset_validation',
                  },
                  {
                    label: 'Anomaly detection validation',
                    value: 'anamoly_detection_validation',
                  },
                ]}
              />
              <br />
              <CFormLabel>Metric</CFormLabel>
              <CFormInput
                type="text"
                style={{ width: '400px' }}
                value={metric.metric}
                required
                onChange={(e) =>
                  updateMetricEntry(
                    i,
                    'metric',
                  )({ target: { value: e.target.value.replaceAll(' ', '_') } })
                }
              />
              <br />
              <CFormLabel>Query for values</CFormLabel>
              <CFormTextarea
                required
                rows="3"
                value={metric.values}
                onChange={updateMetricEntry(i, 'values')}
              />
              <br />
              <CFormLabel>Column names</CFormLabel>
              <CFormInput
                required
                type="text"
                value={metric.columnNames}
                onChange={(e) =>
                  updateMetricEntry(
                    i,
                    'columnNames',
                  )({ target: { value: e.target.value.replaceAll(' ', '_') } })
                }
                style={{ width: '400px' }}
              />
              <br />
              <CFormLabel>GCS Location</CFormLabel>
              <CFormInput
                type="text"
                readOnly
                value={`${BucketLocationPrefix}${form.name}/${metric.metric}/`}
              />
              <br />
              <CFormLabel>Table name</CFormLabel>
              <CFormInput
                type="text"
                readOnly
                value={`${form.namespace}.${form.name}_${metric.metric}`}
              />
            </CCol>
            <br />
          </>
        ))}{' '}
        {form.newMetrics.map((metric, i) => (
          <>
            <CCol
              sm={10}
              style={{
                border: 'solid 1px #d8dbe0',
                width: '100%',
                padding: '0 30px 30px',
                borderRadius: '10px',
              }}
            >
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginTop: '10px',
                }}
              >
                <CFormLabel>Metric #{i + 1}</CFormLabel>
                <div>
                  <img
                    src={TrashIcon}
                    alt="delete"
                    style={{ width: '18px', cursor: 'pointer' }}
                    onClick={deleteNewMetricEntry(i)}
                  />
                </div>
              </div>
              <CFormLabel>Validation tag</CFormLabel>
              <CFormSelect
                style={{ width: '400px' }}
                aria-label=""
                disabled={false}
                onChange={updateNewMetricEntry(i, 'validationTag')}
                options={[
                  {
                    label: 'Raw data validation',
                    value: 'raw_data_validation',
                  },
                  {
                    label: 'Raw vs Dataset validation',
                    value: 'raw_vs_dataset_validation',
                  },
                  {
                    label: 'Anomaly detection validation',
                    value: 'anamoly_detection_validation',
                  },
                ]}
              />
              <br />
              <CFormLabel>Metric</CFormLabel>
              <CFormInput
                type="text"
                style={{ width: '400px' }}
                value={metric.metric}
                required
                onChange={(e) =>
                  updateNewMetricEntry(
                    i,
                    'metric',
                  )({ target: { value: e.target.value.replaceAll(' ', '_') } })
                }
              />
              <br />
              <CFormLabel>Query for values</CFormLabel>
              <CFormTextarea
                required
                rows="3"
                value={metric.values}
                onChange={updateNewMetricEntry(i, 'values')}
              />
              <br />
              <CFormLabel>Column names</CFormLabel>
              <CFormInput
                required
                type="text"
                value={metric.columnNames}
                onChange={(e) =>
                  updateNewMetricEntry(
                    i,
                    'columnNames',
                  )({ target: { value: e.target.value.replaceAll(' ', '_') } })
                }
                style={{ width: '400px' }}
              />
              <br />
              <CFormLabel>GCS Location</CFormLabel>
              <CFormInput
                type="text"
                readOnly
                value={`${BucketLocationPrefix}${form.name}/${metric.metric}/`}
              />
              <br />
              <CFormLabel>Table name</CFormLabel>
              <CFormInput
                type="text"
                readOnly
                value={`${form.namespace}.${form.name}_${metric.metric}`}
              />
            </CCol>
            <br />
          </>
        ))}
        <AddNewButton type={'button'} onClick={addNewMetric}>
          {' '}
          Add new metric
        </AddNewButton>
        <br />
        <br />
        <FlexBox>
          <SaveButton onClick={updateConfig}>Update</SaveButton>
          <ResetFormButton type="button" onClick={cancel}>
            Cancel
          </ResetFormButton>
        </FlexBox>
      </form>
      <Prompt
        when={shouldBlockNavigation}
        message="You have unsaved changes, are you sure you want to leave?"
      />
    </Container>
  )
}

EditHealthCard.propTypes = {}

export default EditHealthCard
