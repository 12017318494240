import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import SimpleBar from 'simplebar-react'
import React, { useState } from 'react'
import { AppSidebarNav } from './AppSidebarNav'
import brandLogo from '../assets/brand/logo.svg'
import { sidebarVisibility } from '../redux/modules/browser/selector'
import { toggleSideBarVisibility } from '../redux/modules/browser/creator'
import { CSidebar, CSidebarBrand, CSidebarNav, CSidebarToggler } from '@coreui/react'
import 'simplebar/dist/simplebar.min.css'

// sidebar nav config
import navigation from '../_nav'

const AppSidebar = ({ sidebarVisibility, toggleSideBarVisibility }) => {
  const [unfoldable, setUnfoldable] = useState(false)

  return (
    <CSidebar position="fixed" unfoldable={unfoldable} visible={sidebarVisibility}>
      <CSidebarBrand className="d-none d-md-flex" to="/">
        <img src={brandLogo} alt="Rapido" style={{ height: '35px' }} />
        {/*<CIcon className="sidebar-brand-narrow" icon={sygnet} height={35} />*/}
      </CSidebarBrand>
      <CSidebarNav>
        <SimpleBar>
          <AppSidebarNav items={navigation} />
        </SimpleBar>
      </CSidebarNav>
      <CSidebarToggler className="d-none d-lg-flex" onClick={toggleSideBarVisibility} />
    </CSidebar>
  )
}

AppSidebar.propTypes = {
  sidebarVisibility: PropTypes.bool,
  toggleSideBarVisibility: PropTypes.func,
}
const mapStateToProps = (state) => ({
  sidebarVisibility: sidebarVisibility(state),
})
const mapActionsToProps = { toggleSideBarVisibility }
export default connect(mapStateToProps, mapActionsToProps)(AppSidebar)
