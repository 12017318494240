import get from 'lodash.get'
import { getCookie } from '../../../utils'

export const getUserToken = ({ session }) => get(session, 'authToken', getCookie('authToken'))

export const isUserAuthorized = ({ session }) => get(session, 'isUserAuthorized', false)

export const userProfileData = ({ session }) => get(session, 'profile.data', {})

export const userGender = ({ session }) => get(session, 'profile.data.gender', '')

export const isUserProfileDataLoading = ({ session }) => get(session, 'profile.isLoading', false)

export const isUserOnboardingComplete = (state) =>
  get(userProfileData(state), 'user.onboarding_complete', false)

export const isEmailVerified = (state) => get(userProfileData(state), 'user.email_verified', false)

export const isUserPasswordChangeRequired = (state) =>
  get(userProfileData(state), 'user.password_change_required', false)

export const getUserOnboardingStatus = (state) =>
  get(userProfileData(state), 'user.onboarding_status')

export const isUserInitialDataLoading = ({ session }) =>
  !get(session, 'profile.isFetched', false) && isUserProfileDataLoading({ session })

export const userEmail = ({ session }) => get(session, 'profile.data.user.email', '')

export const candidateWorkFlowStatus = ({ session }) =>
  get(session, 'profile.data.workflow_status', '')

export const candidateInterviewSlotsSelected = ({ session }) =>
  get(session, 'profile.data.interviewSlotsSelected', [])

export const candidateInterviewSlotConfirmed = ({ session }) =>
  get(session, 'profile.data.interviewSlotsConfirmed', {})

export const candidateExpectedSalary = ({ session }) =>
  get(session, 'profile.data.expected_salary', null)

export const candidateCurrentAnnualSalary = ({ session }) =>
  get(session, 'profile.data.current_annual_salary', null)

export const candidateNoticePeriod = ({ session }) =>
  get(session, 'profile.data.notice_period', null)

export const candidateProfileCompleteness = ({ session }) =>
  get(session, 'profile.data.profile_completeness', null)

export const candidateProfileImageLink = ({ session }) =>
  get(session, 'profile.data.profile_image_link', null)

export const candidateServingNoticePeriod = ({ session }) =>
  get(session, 'profile.data.serving_notice_period', null)

export const candidateExperience = ({ session }) => get(session, 'profile.data.experience', [])

export const candidateEducation = ({ session }) => get(session, 'profile.data.education', [])

export const candidateCertficates = ({ session }) => get(session, 'profile.data.certificates', [])

export const candidateResumeLink = ({ session }) => get(session, 'profile.data.resume_link', '')

export const candidateResumeFile = ({ session }) => get(session, 'profile.data.resume_filename', '')

export const candidateID = ({ session }) => get(session, 'profile.data.id', null)

// todo : need to put some context around this value, position & name of the value are confusing and not really explaining anything about the usage
export const appliedJobId = ({ session }) => get(session, 'appliedJobId')

export const usingDefaultPassword = ({ session }) =>
  get(session, 'profile.data.user.using_default_password', null)

export const isFresher = ({ session }) => get(session, 'profile.data.is_fresher', null)

export const experienceYear = ({ session }) => get(session, 'profile.data.experience_years', null)

export const experienceMonth = ({ session }) => get(session, 'profile.data.experience_months', null)

export const preferredCountry = ({ session }) =>
  get(session, 'profile.data.preferred_country', null)

export const prefferedLocation = ({ session }) =>
  get(session, 'profile.data.preferred_locations', null)

export const openToRemote = ({ session }) => get(session, 'profile.data.open_to_remote_work', null)

export const candidateQualifications = ({ session }) => {
  const education = candidateEducation({ session })
  const certificates = candidateCertficates({ session })
  return [...education, ...certificates].sort(function (a, b) {
    return b.created_at < a.created_at ? -1 : b.created_at > a.created_at ? 1 : 0
  })
}
export const otherCtc = ({ session }) => get(session, 'profile.data.other_ctc_components', null)
export const fixedCtc = ({ session }) => get(session, 'profile.data.fixed_ctc', null)
export const totalSalary = ({ session }) => get(session, 'profile.data.total_salary', null)
export const jobType = ({ session }) => get(session, 'profile.data.job_type', null)
export const jobNature = ({ session }) => get(session, 'profile.data.job_nature', null)
export const fixedSalary = ({ session }) => get(session, 'profile.data.fixed_salary', null)
export const variableSalary = ({ session }) => get(session, 'profile.data.variable_salary', null)
export const bonusSalary = ({ session }) => get(session, 'profile.data.bonus_salary', null)
export const eops = ({ session }) => get(session, 'profile.data.eops', null)
export const desiredSalary = ({ session }) => get(session, 'profile.data.desired_salary', null)
export const perksAndBenefits = ({ session }) =>
  get(session, 'profile.data.perks_and_benefits', null)
