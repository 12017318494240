import 'react-app-polyfill/stable'
import 'core-js'
import React from 'react'
import { ReactKeycloakProvider } from '@react-keycloak/web'
import ReactDOM from 'react-dom'
import App from './App'
import { Loader } from './components'
import { ConnectedRouter } from 'connected-react-router'
import * as serviceWorker from './serviceWorker'
import { Provider } from 'react-redux'
import keycloak from './services/keycloak'
import store, { history } from './redux/store'

ReactDOM.render(
  <ReactKeycloakProvider
    authClient={keycloak}
    initOptions={{ onLoad: 'login-required', flow: 'implicit' }}
    LoadingComponent={Loader}
  >
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <App />
      </ConnectedRouter>
    </Provider>
  </ReactKeycloakProvider>,
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
