import { reportsServicesConstants } from './creator'

const defaultState = { data: [], filter: {}, sort: {}, loading: false }

export const cleanseData = (data) => {
  return data.map((_) => ({
    ..._,
    // reportDate: new Date(_.reportDate).toDateString(),
    // expiry: new Date(_.expiry).toDateString(),
    reportDate: _.reportDate.split('T')[0],
    expiry: _.expiry.split('T')[0],
    questionId: _.questionId || '',
  }))
}

const MAPPED_REDUCERS = {
  [reportsServicesConstants.SET_LOADING_STATE]: (state, { payload }) => ({
    ...state,
    loading: Boolean(payload),
  }),
  [reportsServicesConstants.GET_USER_REPORT_REQUESTS.SUCCESS]: (state, { response }) => ({
    ...state,
    data: cleanseData(response),
    loading: false,
  }),
  [reportsServicesConstants.SET_USER_REPORT_REQUESTS]: (state, { payload }) => ({
    ...state,
    data: payload,
    loading: false,
  }),
  [reportsServicesConstants.LOAD_MORE.SUCCESS]: (state, { response }) => ({
    ...state,
    data: [...state.data, ...cleanseData(response)],
    loading: false,
  }),
  [reportsServicesConstants.SET_FILTER]: (state, { payload }) => ({
    ...state,
    filter: payload,
  }),
  [reportsServicesConstants.SET_SORT]: (state, { payload }) => ({
    ...state,
    sort: payload,
  }),
}

function sessionReducer(state = defaultState, action) {
  const handler = MAPPED_REDUCERS[action.type]

  return handler ? handler(state, action) : state
}

export default sessionReducer
