import set from 'lodash.set'

import { constants } from './creator'
import { createAsyncDataObject } from '../utils'

const defaultState = {
  layout: {
    sidebarVisibility: false,
  },
}

const MAPPED_REDUCERS = {
  [constants.TOGGLE_SIDEBAR_VISIBILITY]: (state, { type, payload, meta }) => ({
    ...state,
    layout: {
      ...state.layout,
      sidebarVisibility: !state.layout.sidebarVisibility,
    },
  }),
}

function browserReducer(state = defaultState, action) {
  const handler = MAPPED_REDUCERS[action.type]

  return handler ? handler(state, action) : state
}

export default browserReducer
